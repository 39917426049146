<template>
  <div>
    <b-card>
      <b-row class="justify-content-md-center">
        <b-col md="6" cols="12">
          <b-form-group label="Choose an Account" label-for="">
            <v-select v-model="selectedAccount" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
              :options="accounts" accountSelected="" @input="showAccApps" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col offset="6" cols="6">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-1 d-flex align-items-center">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-modal id="modal-danger" ok-only ok-variant="danger" ok-title="Yes" modal-class="modal-danger" centered
          title="Delete" @ok="deleteNotif">
          <b-card-text>
            Are you sure you want to delete this notification?
          </b-card-text>
        </b-modal>

        <b-modal id="modal-danger-for-delete" ok-variant="danger" ok-title="Confirm" modal-class="modal-danger" centered
          title="Delete" @ok="deleteNotifRow">
          <b-card-text>
            Are you sure you want to delete this notification?
          </b-card-text>
        </b-modal>
        <b-modal id="modal-default" ok-variant="outline-success" scrollable :title="appTitle" size="xl"
          :hide-footer="true" ok-only ok-title="Close">
          <div class="day-group">
            <div class="demo-inline-spacing days-div">
              <div>Filter by days:</div>
              <br />
              <span class="day-span" v-for="day in weekDays" :key="day.value" @click="showDayNotifs(day.value)">
                <b-avatar class="day-avatar" variant="primary">{{
                  day.text
                }}</b-avatar>
              </span>
            </div>
          </div>
          <b-table ref="form" v-show="true" :items="appNotifications" :fields="tableFields" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection">
            <template #cell(notification_content)="data">
              <validation-provider #default="{ errors }" name="Notification content" rules="required">
                <div class="inputs">
                  <b-form-textarea type="text" v-model="data.item.notification_content"
                    :state="errors.length > 0 ? false : null" class="content-notif"></b-form-textarea>

                  <span class="require">*</span>
                </div>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </template>
            <template #cell(notification_title)="data">
              <div class="inputs">
                <b-form-input v-model="data.item.notification_title" class="form-control title-notif"
                  required></b-form-input>
                <span class="require">*</span>
              </div>
            </template>
            <template #cell(notif_day)="data">
              <!-- <b-form-input type="number" :value="data.value"></b-form-input> -->
              <v-select style="display: none" v-model="data.item.notif_day"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="days" disabled />
            </template>
            <template #cell(notif_time)="data">
              <!-- <b-form-input type="number" :value="data.value"></b-form-input> -->
              <div class="timepicker-section inputs">
                <b-form-input type="time" placeholder="HH:mm" style="width: 140px" v-model="data.item.notif_time"
                  class="time-notif"></b-form-input>
              </div>
            </template>

            <template #cell(notification_link)="data">
              <validation-provider #default="{ errors }" name="Notification link" rules="url">
                <b-form-input v-model="data.item.notification_link" class="form-control"
                  :state="errors.length > 0 ? false : null"></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </template>
            <template #cell(action)="data">
              <b-col lg="3" class="mb-50 special-column list-col d-flex">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-warning"
                  class="btn-icon rounded-circle mt-0 mt-md-2" v-b-tooltip.hover.right @click="toggleTooltip(data.index)">
                  <!-- :disabled="invalid" -->
                  <feather-icon icon="InfoIcon" size="25" />
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                  class="btn btn-icon rounded-circle mt-0 mt-md-2" v-b-tooltip.hover.left title="Delete"
                  @click="removeItemPopup(data.item, data.index)">
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
                <!-- <b-button
                  style="color: #6d8bb4"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle mt-0 mt-md-2"
                  v-b-tooltip.hover
                  title="Duplicate"
                  @click="duplicateNotif(data.item, `duplicate`)"
                >
                  <feather-icon icon="CopyIcon" size="20" />
                </b-button> -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-success"
                  class="btn-icon rounded-circle mt-0 mt-md-2" v-b-tooltip.hover.right title="Save"
                  @click="saveEdits(data.item, data.index)">
                  <!-- :disabled="invalid" -->
                  <feather-icon icon="SaveIcon" size="20" />
                </b-button>
                <!-- <i class="fa-regular fa-circle-info" ></i> -->
                <div v-if="showTooltipArray[data.index]" class="tooltip-2" style="min-width: 270px">
                  Ideal Time to Program this notif : <br />
                  Morning : {{ phases[0] }} <br />
                  afternoon : {{ phases[1] }} <br />
                  evening : {{ phases[2] }}
                </div>
              </b-col>
            </template>
            <!-- </ValidationObserver> -->
          </b-table>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
          <!-- table -->
        </b-modal>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="appItems"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(action)="data">
              <b-button-group>
                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" type="button"
                  class="btn-icon rounded-circle" v-b-tooltip.hover title="Edit notifications"
                  @click="popupNotifications(data.item)">
                  <feather-icon icon="EditIcon" size="20" />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-col>
        <b-col cols="6">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BAvatarGroup,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardText,
  BButton,
  BModal,
  VBModal,
  BFormTextarea,
  BForm,
  BFormTimepicker,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import store from "@/store";
import router from "@/router";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    BForm,
    vSelect,
    BTable,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BFormTimepicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      daysAreFiltered: true,
      selectedFilterDay: null,
      tableFields: [
        { key: "notification_title", label: "Notification title" },
        { key: "notification_content", label: "Notification content" },
        // { key: "notif_day", label: "Day", sortable: false },
        { key: "notification_link", label: "Notification link" },
        { key: "notif_time", label: "Time", sortable: false },
        "action",
      ],
      name: "",

      phases: [],
      required,
      nextTodoId: 2,
      dayNames: [
        0,
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      showTooltipArray: [],
      selectedAccount: "",
      accountSelected: "",
      accounts: [],
      valueOfTime: new Date(),
      account_name: null,
      account_link: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "notif_day",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      notifIdToDelete: null,
      sendData: [],
      fields: [
        {
          //   key: "account_name",
          key: "title",
          label: "App Name",
        },

        "Action",
        // "send",
      ],
      appId_selected: 0,
      items: [],
      appItems: [],
      clicked: [],
      appNotifications: [],
      days: [
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
        { text: 5, value: 5 },
        { text: 6, value: 6 },
        { text: 7, value: 7 },
      ],
      weekDays: [
        { text: "D1", value: 1 },
        { text: "D2", value: 2 },
        { text: "D3", value: 3 },
        { text: "D4", value: 4 },
        { text: "D5", value: 5 },
        { text: "D6", value: 6 },
        { text: "D7", value: 7 },
      ],
      filterDays: [1, 2, 3, 4, 5, 6, 7],
      saveData: [],
      deleteData: [],
      indexToBeDeleted: null,
      initialAppNotification: [],
      thisAppId: null,
      thisAccountId: null,
      appTitle: null,
      saveNotifs: [],
      showTooltip: false,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.totalRows = this.appItems.length;
    this.initTrHeight();
  },
  created() {
    this.showAllAccounts();
    this.clicked = [];
  },
  destroyed() { },
  methods: {
    toggleTooltip(index) {
      this.$set(this.showTooltipArray, index, !this.showTooltipArray[index]);
    },
    showDayNotifs(day) {
      let element = document.getElementsByClassName("day-avatar")[day - 1];
      this.daysAreFiltered = true;
      var dayAvatars = document.getElementsByClassName("day-avatar");
      dayAvatars.forEach((dayAvatar) => {
        dayAvatar.parentElement.classList.remove("day-active");
      });
      element.parentElement.className += " day-active";

      this.selectedFilterDay = day;
      this.appNotifications = [];
      var found;
      found = this.saveNotifs.filter((e) => e.notif_day == day);
      found.forEach((dayNotif) => {
        this.appNotifications.push(dayNotif);
      });
      this.showTooltipArray = new Array(this.appNotifications.length).fill(
        false
      );
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("form submitted!");
        }
      });
    },
    async duplicateNotif(dataItem, from) {
      const notifData = {
        notification_title:
          from === "save"
            ? dataItem.notification_title
            : `Copy - ${dataItem.notification_title}`,
        notification_content: dataItem.notification_content,
        notification_link: dataItem.notification_link,
        appId: dataItem.appId,
        account_id: dataItem.account_id,
        notif_day: dataItem.notif_day.value || dataItem.notif_day,
        notif_time: dataItem.notif_time,
      };
      await axios
        .post("/addNotification", notifData)
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
      await axios
        .post("/getNotifsByApps", {
          notif_day: "1",
          appId: this.thisAppId,
        })
        .then(({ data }) => {
          this.appNotifications = data.notifs;
          this.saveNotifs = this.appNotifications;
          this.totalNotifRows = this.appNotifications.length;
          this.showDayNotifs(dataItem.notif_day);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveEdits(item, index) {
      // let toBeSaved = this.appNotifications[index];
      //####################################### check if EDIT or ADD ######################################//
      if (item.id) {
        await axios
          .post("/updateNotification", {
            id: item.id,
            notification_title: item.notification_title,
            notification_content: item.notification_content,
            appId: item.appId,
            account_id: item.account_id,
            notif_day: item.notif_day.value || item.notif_day,
            notif_time: item.notif_time,
            notification_link: item.notification_link,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.showToast(
                "success",
                "SaveIcon",
                "Done",
                response.data.message
              );
              // router.push({ name: "notifications" });
              return;
              // this.$refs.resetButton.click();
            } else if (response.data.status == "failed") {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Something went wrong!"
              );
            } else if (response.data.status == "warning") {
              this.showToast(
                "warning",
                "AlertOctagonIcon",
                "Alert",
                response.data.message
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          });
      } else {
        var inputsTitle = document.getElementsByClassName("title-notif");
        var inputsContent = document.getElementsByClassName("content-notif");
        var inputsTimes = document.getElementsByClassName("time-notif");
        var Len = inputsTitle.length;
        inputsContent[Len - 1].style.border = "solid 1px #404656";
        inputsTitle[Len - 1].style.border = "solid 1px #404656";
        inputsTimes[Len - 1].style.border = "solid 1px #404656";

        if (
          item.notification_title != null &&
          item.notification_content != null &&
          item.notif_time != undefined
        ) {
          this.indexToBeDeleted = index;
          this.duplicateNotif(item, "save");
        } else {
          if (item.notification_content === null) {
            inputsContent[Len - 1].style.border = "solid 1px red";
          }
          if (item.notification_title === null) {
            inputsTitle[Len - 1].style.border = "solid 1px red";
          }
          if (!item.notif_time) {
            inputsTimes[Len - 1].style.border = "solid 1px red";
          }
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Make sure all fill !"
          );
        }
      }
    },

    repeateAgain() {
      this.appNotifications.push({
        list_id: (this.nextTodoId += this.nextTodoId),
        account: {},
        account_id: this.selectedAccount.value,
        app: {},
        appId: this.thisAppId,
        notif_day: this.selectedFilterDay || 7,
        notification_title: null,
        notification_link: null,
        notification_content: null,
      });
    },

    removeItemPopup(item, index) {
      // this.deleteData = this.appNotifications[index];
      this.deleteData = item;
      this.$root.$emit("bv::show::modal", "modal-danger-for-delete");
      this.indexToBeDeleted = index;
    },
    deleteNotifRow() {
      if (!this.deleteData.id) {
        this.appNotifications.splice(this.indexToBeDeleted, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight);
      } else {
        axios
          .post("/destroyNotification", {
            id: this.deleteData.id,
          })
          .then(({ data }) => {
            if (data.status == "Unauthorized") {
              removeLocalStorageItems();
              router.push({ name: "auth-login" });
            } else if (data.status == "success") {
              this.saveNotifs = this.saveNotifs.filter(
                (x) => x.id === this.deleteData.id
              );

              this.showToast("success", "SaveIcon", "Done", data.message);
              this.appNotifications.splice(this.indexToBeDeleted, 1);
            } else if (data.status == "failed") {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Something went wrong!"
              );
            } else if (data.status == "warning") {
              this.showToast(
                "warning",
                "AlertOctagonIcon",
                "Alert",
                data.message
              );
            }
          })
          .catch(console.error);
      }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    popupNotifications(app) {
      var id = app.id;
      this.appId_selected = app.id;
      this.daysAreFiltered = true;
      this.selectedFilterDay = 1;
      this.thisAppId = id;
      this.appTitle = "Edit notifications for " + app.title;

      this.$root.$emit("bv::show::modal", "modal-default");
      // this.$root.$emit("bv::show::modal", "modal-warning-for-edit");
      var currentDate = new Date();
      axios
        .post("/getNotifsByApps", {
          appId: id,
          notif_day: currentDate.getDay(),
        })
        .then(({ data }) => {
          this.appNotifications = data.notifs;
          this.saveNotifs = this.appNotifications;
          this.initialAppNotification = data.notifs;
          this.totalNotifRows = this.appNotifications.length;
          const positionAcc = data.acc.filter(
            (x) => x.id === this.selectedAccount.value
          );
          var start = this.getPriorityAcc(data.acc, positionAcc[0].position);
          var end = positionAcc[0].apps_count * 3;
          var endBackUp = end;
          const paddedEnd = end < 10 ? "0" + end : end;
          let phase1 = "06:";
          let phase2 = "12:";
          let phase3 = "18:";
          if (start === 0) {
            this.phases = [
              phase1 + "00 to 6:" + paddedEnd,
              phase2 + "00 to 12:" + paddedEnd,
              phase3 + "00 to 18:" + paddedEnd,
            ];
          } else {
            endBackUp = endBackUp + start;
            endBackUp = endBackUp < 10 ? "0" + endBackUp : endBackUp;

            start = start < 10 ? "0" + start : start;
            this.phases = [
              phase1 + start + " to " + phase1 + endBackUp,
              phase2 + start + " to " + phase2 + endBackUp,
              phase3 + start + " to " + phase3 + endBackUp,
            ];
          }
        })
        .then(() => {
          //
          this.showDayNotifs(currentDate.getDay());
        })
        .catch(console.error);
    },
    getPriorityAcc(data, pos) {
      // const HaveNotifs = this.appNotifications.length;
      //
      //
      const back_pos = pos;
      var totalNotif = 0;
      while (pos >= 1) {
        let account = data.filter((x) => x.position === pos);

        totalNotif += account[0].apps_count;
        pos--;
      }
      let account = data.filter((x) => x.position === back_pos);
      //
      return (totalNotif - account[0].apps_count) * 3;
    },
    showAccApps(account) {
      var apps = [];
      this.appItems = [];
      axios
        // .post(`/get-apps-by-account/${this.accSelected.value}`)
        .post(`/get-apps-by-account`, {
          accountId: account.value,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then(({ data }) => {
          data.apps.forEach((app) => {
            if (app.credentials_id) {
              apps.push(app);
            }
          });

          this.appItems = apps;
          this.totalRows = this.appItems.length;
        });
    },

    setDeleteId(id) {
      this.notifIdToDelete = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },

    initialize() {
      setTimeout(() => {
        axios
          .post("/initilizeSent")
          .then((response) => {
            this.clicked = [];
            this.showAccountNotifs();
          })
          .catch((error) => {
            this.showToast(
              "success",
              "SaveIcon",
              "Done",
              "Notifications initialized"
            );
          });
      }, 10);
    },
    async showAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then((response) => {
          response.data.accounts.forEach((element) => {
            this.accounts.push({
              title: element.account_name,
              value: element.id,
            });
          });
        })
        .catch(console.error);
    },
    deleteNotif() {
      axios
        .post("/destroyNotification", {
          id: this.notifIdToDelete,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.showAccountNotifs();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },

    showAccountNotifs() {
      var currentDate = new Date();
      var count = 0;
      axios
        .post("/getNotifsByAccount", {
          account_id: this.selectedAccount.value,
          notif_day: currentDate.getDay(),
        })
        .then(({ data }) => {
          this.items = data[0];
          data[0].forEach((data) => {
            axios
              .post("/checkIfSent", {
                notif_id: data.id,
              })
              .then((response) => {
                if (response.data.message == 1) {
                  this.clicked.push(data.id);
                }
              })
              .catch((error) => {
                console.error(error);
              });
            count++;
          });

          this.totalRows = this.items.length;
        })
        .catch(console.error);
    },
    showAllNotifs() {
      this.$store
        .dispatch("appsManager/getAllNotifs")
        .then(({ data }) => {
          this.items = data[0];
          this.totalRows = this.items.length;
        })
        .catch(console.error);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.list-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
  overflow-y: scroll !important;
  position: relative;
}

.list-col {
  min-width: 200px !important;
  // widows: ;
}

.special-column {
  min-width: 120px !important;
}

@media screen and (max-width: 1200px) {
  .list-row {
    overflow-x: scroll;
  }
}

.daysFilter {
  font-size: 0.9em;
}

.day-avatar:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.day-buttons {
  font-size: 1em !important;
}

.buttons-box {
  display: flex;
  flex-direction: row;
  margin: 0 0 25px 0;
  align-items: center;
  justify-content: center;
}

.pull-up {
  width: 45px !important;
  height: 35px !important;
}

.day-active {
  cursor: pointer;
  transform: scale(1.25);
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
  border: solid 2px #D5A419;
  border-radius: 50%;
}

.day-group {
  padding-bottom: 8px;
}

.days-div {
  width: fit-content !important;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.reset-filter {
  color: grey;
  font-size: 1em !important;
}

.reset-filter:hover {
  cursor: pointer;
  font-size: 1.05em !important;
}

.timepicker-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

.inputs {
  position: relative;
}

.require {
  position: absolute;
  top: 0;
  right: -10px;
  color: red;
  font-weight: bold;
}

// .tooltip {
// }
.tooltip-2 {
  position: absolute;
  top: -70px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.tooltip-2::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;

  min-width: 60px !important;
}
</style>